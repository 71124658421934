import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  BookOutlined,
  PlusOutlined,
  FolderOutlined,
  BarChartOutlined,
  SearchOutlined
} from '@ant-design/icons';

const Navbar = () => {
  const location = useLocation();

  const items = [
    {
      key: '/api/bookmarks',
      icon: <BookOutlined />,
      label: <Link to="/api/bookmarks">书签列表</Link>,
    },
    {
      key: '/api/save',
      icon: <PlusOutlined />,
      label: <Link to="/api/save">添加书签</Link>,
    },
    {
      key: '/api/categories/list',
      icon: <FolderOutlined />,
      label: <Link to="/api/categories/list">分类管理</Link>,
    },
    {
      key: '/api/stats',
      icon: <BarChartOutlined />,
      label: <Link to="/api/stats">统计信息</Link>,
    },
    {
      key: '/api/search',
      icon: <SearchOutlined />,
      label: <Link to="/api/search">搜索</Link>,
    },
  ];

  return (
    <Menu
      theme="dark"
      mode="horizontal"
      selectedKeys={[location.pathname]}
      items={items}
      style={{ lineHeight: '64px' }}
    />
  );
};

export default Navbar; 