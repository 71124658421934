import { useState, useEffect } from 'react';
import { Card, Row, Col, List, Tag, Statistic } from 'antd';
import {
  BookOutlined,
  GlobalOutlined,
  TagsOutlined,
  FolderOutlined
} from '@ant-design/icons';

interface Stats {
  domains: number;
  bookmarks: number;
  tags: number;
  categories: number;
  topDomains: { domain: string; count: number }[];
  topTags: { tag: string; count: number }[];
  recentBookmarks: {
    title: string;
    url: string;
    domain: string;
    updatedAt: string;
  }[];
}

const Statistics = () => {
  const [stats, setStats] = useState<Stats | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await fetch('/api/stats');
      if (!response.ok) throw new Error('Failed to fetch statistics');
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Failed to fetch statistics:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>加载中...</div>;
  }

  if (!stats) {
    return <div>无法加载统计信息</div>;
  }

  return (
    <div>
      <h2>统计信息</h2>
      
      {/* 基础统计数据 */}
      <Row gutter={16} style={{ marginBottom: 24 }}>
        <Col span={6}>
          <Card>
            <Statistic
              title="总书签数"
              value={stats.bookmarks}
              prefix={<BookOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="域名数"
              value={stats.domains}
              prefix={<GlobalOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="标签数"
              value={stats.tags}
              prefix={<TagsOutlined />}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="分类数"
              value={stats.categories}
              prefix={<FolderOutlined />}
            />
          </Card>
        </Col>
      </Row>

      {/* 详细统计信息 */}
      <Row gutter={16}>
        <Col span={8}>
          <Card title="热门域名" style={{ marginBottom: 16 }}>
            <List
              size="small"
              dataSource={stats.topDomains}
              renderItem={item => (
                <List.Item>
                  <span>{item.domain}</span>
                  <Tag color="blue">{item.count}</Tag>
                </List.Item>
              )}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card title="热门标签" style={{ marginBottom: 16 }}>
            <List
              size="small"
              dataSource={stats.topTags}
              renderItem={item => (
                <List.Item>
                  <span>{item.tag}</span>
                  <Tag color="green">{item.count}</Tag>
                </List.Item>
              )}
            />
          </Card>
        </Col>

        <Col span={8}>
          <Card title="最近更新" style={{ marginBottom: 16 }}>
            <List
              size="small"
              dataSource={stats.recentBookmarks}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <a href={item.url} target="_blank" rel="noopener noreferrer">
                        {item.title}
                      </a>
                    }
                    description={
                      <>
                        <Tag>{item.domain}</Tag>
                        <span>{new Date(item.updatedAt).toLocaleDateString()}</span>
                      </>
                    }
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Statistics; 