import { useState, useEffect } from 'react';
import { List, Card, Tag, Space, Button, message, Popconfirm } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

interface Bookmark {
  url: string;
  title: string;
  description?: string;
  tags?: string[];
  parentCategory?: string;
  subCategory?: string;
  createdAt: string;
  updatedAt: string;
}

const BookmarkList = () => {
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBookmarks();
  }, []);

  const fetchBookmarks = async () => {
    try {
      const response = await fetch('/api/bookmarks/list');
      if (!response.ok) throw new Error('Failed to fetch bookmarks');
      const data = await response.json();
      setBookmarks(data);
    } catch (error) {
      message.error('获取书签失败');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (url: string) => {
    try {
      const response = await fetch('/api/bookmarks/delete', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url })
      });

      if (!response.ok) throw new Error('Failed to delete bookmark');
      
      message.success('书签删除成功');
      fetchBookmarks();
    } catch (error) {
      message.error('删除书签失败');
      console.error(error);
    }
  };

  // 按域名对书签进行分组
  const groupedBookmarks = bookmarks.reduce((groups, bookmark) => {
    const domain = new URL(bookmark.url).hostname;
    if (!groups[domain]) {
      groups[domain] = [];
    }
    groups[domain].push(bookmark);
    return groups;
  }, {} as Record<string, Bookmark[]>);

  const renderBookmarkList = () => {
    return Object.entries(groupedBookmarks).map(([domain, domainBookmarks]) => (
      <Card 
        key={domain} 
        title={domain}
        style={{ marginBottom: 16 }}
      >
        <List
          dataSource={domainBookmarks}
          renderItem={(bookmark) => (
            <List.Item
              actions={[
                <Button 
                  key="edit"
                  icon={<EditOutlined />}
                  type="link"
                  onClick={() => {/* TODO: 实现编辑功能 */}}
                />,
                <Popconfirm
                  key="delete"
                  title="确定要删除这个书签吗？"
                  onConfirm={() => handleDelete(bookmark.url)}
                  okText="确定"
                  cancelText="取消"
                >
                  <Button 
                    icon={<DeleteOutlined />}
                    type="link"
                    danger
                  />
                </Popconfirm>
              ]}
            >
              <List.Item.Meta
                title={
                  <a href={bookmark.url} target="_blank" rel="noopener noreferrer">
                    {bookmark.title}
                  </a>
                }
                description={
                  <Space direction="vertical">
                    <span>{bookmark.description}</span>
                    <Space>
                      {bookmark.tags?.map(tag => (
                        <Tag key={tag} color="blue">{tag}</Tag>
                      ))}
                      {bookmark.parentCategory && (
                        <Tag color="green">
                          {bookmark.parentCategory}
                          {bookmark.subCategory && ` / ${bookmark.subCategory}`}
                        </Tag>
                      )}
                    </Space>
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      </Card>
    ));
  };

  return (
    <div>
      <h2>我的书签</h2>
      {loading ? (
        <div>加载中...</div>
      ) : (
        renderBookmarkList()
      )}
    </div>
  );
};

export default BookmarkList; 