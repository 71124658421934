import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'antd/dist/reset.css';

import App from './App';
import BookmarkList from './components/BookmarkList';
import AddBookmark from './components/AddBookmark';
import Categories from './components/Categories';
import Statistics from './components/Statistics';
import Search from './components/Search';

// 创建路由配置
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <BookmarkList />,
      },
      {
        path: 'api/bookmarks',
        element: <BookmarkList />,
      },
      {
        path: 'api/save',
        element: <AddBookmark />,
      },
      {
        path: 'api/categories/list',
        element: <Categories />,
      },
      {
        path: 'api/stats',
        element: <Statistics />,
      },
      {
        path: 'api/search',
        element: <Search />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <RouterProvider router={router} />
    </ConfigProvider>
  </React.StrictMode>
); 