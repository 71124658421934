import { useState, useEffect } from 'react';
import { Input, Select, List, Card, Tag, Space, Empty } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Search: SearchInput } = Input;

interface SearchResult {
  title: string;
  url: string;
  description?: string;
  tags?: string[];
  parentCategory?: string;
  subCategory?: string;
  domain: string;
  updatedAt: string;
}

interface SearchResponse {
  total: number;
  results: SearchResult[];
}

interface Category {
  name: string;
  subcategories: { name: string }[];
}

const Search = () => {
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [tags, setTags] = useState<{ tag: string; count: number }[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [selectedTag, setSelectedTag] = useState<string>();

  useEffect(() => {
    fetchCategories();
    fetchTags();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch('/api/categories/list');
      if (!response.ok) throw new Error('Failed to fetch categories');
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await fetch('/api/domain/tags');
      if (!response.ok) throw new Error('Failed to fetch tags');
      const data = await response.json();
      setTags(data);
    } catch (error) {
      console.error('Failed to fetch tags:', error);
    }
  };

  const handleSearch = async (query: string) => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      if (query) params.append('q', query);
      if (selectedCategory) params.append('category', selectedCategory);
      if (selectedTag) params.append('tag', selectedTag);

      const response = await fetch(`/api/search?${params.toString()}`);
      if (!response.ok) throw new Error('Search failed');
      
      const data: SearchResponse = await response.json();
      setResults(data.results);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>搜索书签</h2>
      
      <Space direction="vertical" style={{ width: '100%', marginBottom: 24 }}>
        <SearchInput
          placeholder="输入关键词搜索"
          enterButton={<SearchOutlined />}
          size="large"
          loading={loading}
          onSearch={handleSearch}
        />
        
        <Space>
          <Select
            style={{ width: 200 }}
            placeholder="选择分类"
            allowClear
            onChange={setSelectedCategory}
            options={categories.map(cat => ({
              label: cat.name,
              value: cat.name
            }))}
          />
          
          <Select
            style={{ width: 200 }}
            placeholder="选择标签"
            allowClear
            onChange={setSelectedTag}
            options={tags.map(tag => ({
              label: tag.tag,
              value: tag.tag
            }))}
          />
        </Space>
      </Space>

      {results.length > 0 ? (
        <List
          dataSource={results}
          renderItem={(item) => (
            <Card style={{ marginBottom: 16 }}>
              <List.Item>
                <List.Item.Meta
                  title={
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      {item.title}
                    </a>
                  }
                  description={
                    <Space direction="vertical">
                      <span>{item.description}</span>
                      <Space>
                        <Tag color="blue">{item.domain}</Tag>
                        {item.tags?.map(tag => (
                          <Tag key={tag} color="green">{tag}</Tag>
                        ))}
                        {item.parentCategory && (
                          <Tag color="orange">
                            {item.parentCategory}
                            {item.subCategory && ` / ${item.subCategory}`}
                          </Tag>
                        )}
                        <span style={{ color: '#999' }}>
                          更新于: {new Date(item.updatedAt).toLocaleDateString()}
                        </span>
                      </Space>
                    </Space>
                  }
                />
              </List.Item>
            </Card>
          )}
        />
      ) : (
        <Empty description="暂无搜索结果" />
      )}
    </div>
  );
};

export default Search; 