import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import Navbar from './components/Navbar';

const { Content } = Layout;

function App() {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Content style={{ padding: '24px', background: '#fff' }}>
        <Outlet />
      </Content>
    </Layout>
  );
}

export default App; 