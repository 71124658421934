import { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;

interface Category {
  name: string;
  subcategories: { name: string }[];
}

interface FormValues {
  url: string;
  title: string;
  description?: string;
  tags?: string[];
  parentCategory?: string;
  subCategory?: string;
}

const AddBookmark = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [tags, setTags] = useState<{ tag: string; count: number }[]>([]);
  const [selectedParentCategory, setSelectedParentCategory] = useState<string>();

  useEffect(() => {
    fetchCategories();
    fetchTags();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch('/api/categories/list');
      if (!response.ok) throw new Error('Failed to fetch categories');
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      message.error('获取分类失败');
      console.error(error);
    }
  };

  const fetchTags = async () => {
    try {
      const response = await fetch('/api/domain/tags');
      if (!response.ok) throw new Error('Failed to fetch tags');
      const data = await response.json();
      setTags(data);
    } catch (error) {
      message.error('获取标签失败');
      console.error(error);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      const response = await fetch('/api/save', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
      });

      if (!response.ok) throw new Error('Failed to save bookmark');

      message.success('书签保存成功');
      navigate('/');
    } catch (error) {
      message.error('保存书签失败');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleParentCategoryChange = (value: string) => {
    setSelectedParentCategory(value);
    form.setFieldValue('subCategory', undefined);
  };

  return (
    <div style={{ maxWidth: 600, margin: '0 auto' }}>
      <h2>添加书签</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          name="url"
          label="URL"
          rules={[{ required: true, message: '请输入URL' }]}
        >
          <Input placeholder="https://example.com" />
        </Form.Item>

        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true, message: '请输入标题' }]}
        >
          <Input placeholder="书签标题" />
        </Form.Item>

        <Form.Item
          name="description"
          label="描述"
        >
          <TextArea rows={4} placeholder="书签描述（可选）" />
        </Form.Item>

        <Form.Item
          name="tags"
          label="标签"
        >
          <Select
            mode="tags"
            placeholder="选择或输入标签"
            options={tags.map(tag => ({ label: tag.tag, value: tag.tag }))}
          />
        </Form.Item>

        <Form.Item
          name="parentCategory"
          label="父分类"
        >
          <Select
            placeholder="选择父分类"
            onChange={handleParentCategoryChange}
            options={categories.map(cat => ({ label: cat.name, value: cat.name }))}
          />
        </Form.Item>

        <Form.Item
          name="subCategory"
          label="子分类"
        >
          <Select
            placeholder="选择子分类"
            disabled={!selectedParentCategory}
            options={
              categories
                .find(cat => cat.name === selectedParentCategory)
                ?.subcategories
                .map(sub => ({ label: sub.name, value: sub.name })) || []
            }
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            保存书签
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddBookmark; 