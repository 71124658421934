import { useState, useEffect } from 'react';
import { Tree, Button, Modal, Form, Input, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import type { DataNode } from 'antd/es/tree';

interface Category {
  name: string;
  subcategories: { name: string }[];
}

const Categories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await fetch('/api/categories/list');
      if (!response.ok) throw new Error('Failed to fetch categories');
      const data = await response.json();
      setCategories(data);
    } catch (error) {
      message.error('获取分类失败');
      console.error(error);
    }
  };

  const handleAddCategory = async (values: { parentCategory: string; subCategory?: string }) => {
    setLoading(true);
    try {
      const response = await fetch('/api/categories/save', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values)
      });

      if (!response.ok) throw new Error('Failed to save category');

      message.success('分类保存成功');
      setModalVisible(false);
      form.resetFields();
      fetchCategories();
    } catch (error) {
      message.error('保存分类失败');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const treeData: DataNode[] = categories.map(category => ({
    title: category.name,
    key: category.name,
    children: category.subcategories.map(sub => ({
      title: sub.name,
      key: `${category.name}-${sub.name}`,
      isLeaf: true
    }))
  }));

  return (
    <div>
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between' }}>
        <h2>分类管理</h2>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setModalVisible(true)}
        >
          添加分类
        </Button>
      </div>

      <Tree
        showLine
        defaultExpandAll
        treeData={treeData}
      />

      <Modal
        title="添加分类"
        open={modalVisible}
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddCategory}
        >
          <Form.Item
            name="parentCategory"
            label="父分类"
            rules={[{ required: true, message: '请输入父分类名称' }]}
          >
            <Input placeholder="输入父分类名称" />
          </Form.Item>

          <Form.Item
            name="subCategory"
            label="子分类"
          >
            <Input placeholder="输入子分类名称（可选）" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              保存
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Categories; 